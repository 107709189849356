.errorsList {
}

.swapsList {
  margin: 0 !important;
}

.errorsList {
  margin: 0 !important;

  :global(.ant-table-row){
    // background-color: #f8acac;
  }
}