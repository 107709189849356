.table-header {
    display: flex;
    align-items: end;
    justify-content: space-between;
    & .btn-no-outline {
        border: none;
    }
    min-width: 400px;
}

.table-header-btn_export {
    margin-left: 40px;
    color: #00A7EE;
    border-color: #00A7EE;
}

.header-space {
    margin-left: 24px;
}

.table-header-sortable {
    background: white;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    width: 100%;
    z-index: 1030;
    text-align: center;
}

.table-header-checkbox-group{
    display: flex;
    flex-direction: column;
}

.table-header-sortable .ant-checkbox {
    display: none;
}

.table-header-sortable td {
    display: flex;
    justify-content: center;
}

.table-header-btn {
    margin-right: 20px;
}

.table-header-h1 {
    margin: 0 20px 0 0;
}

.toolBar-wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    grid-gap: 20px !important;
}

@media screen and (max-width: 1024px) {
    .table-header-btn_collapsed {
        margin-right: 0;
    }
}

@media screen and (max-width: 1200px) {
    .table-header-btn_open {
        margin-right: 0;
    }

    .table-header-h1 {
        font-size: 1.5em;
    }
}
.logo_image{
      padding-right: 20px;
      cursor: pointer;
    }
