@import '@styles/variables';
.name {
    color: $deepBlue;
}

.footerBtnGroup {
    display: flex;
    justify-content: end;
    padding-left: 150px;
    background-color: #f4f8fc;

    a {
        line-height: 30px;
    }
}

.additionalInfoErrorServerTable {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    // justify-content: flex-end;
    // padding-right: 130px;
    white-space: pre-wrap;
}

.additionalInfoErrorServer {
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
    white-space: pre-wrap;
}

// .additionalInfoSymbol{
//   display: flex;
//   justify-content: flex-start;
//   padding-left: 40px;
// }

.mainInfo {
    margin-top: 10px;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
}
.mainInfo span {
    padding: 5px;
}

.back {
}

.contentWrapperHistoryDetaDetails {
}

.headerButtonBack {
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pageWrapperHistoryDetaDetails {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(229, 229, 229);
}

.errorCounterMT {
    width: 66%;
    text-align: right;
}

.errorCounterFIX {
    width: 76%;
    text-align: right;
}

.collapseIcon {
    width: 10px;
    height: 10px;
}

.historyBtnClose {
    width: 1000px;
}
.historyBtnClose button {
    width: 100%;
}
.containerModal {
    position: relative;
}
.headerBlocks {
    // background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}
.headerContainerLeft {
    display: flex;
    flex-direction: column;
}
.headerContainerLogo {
    width: 40px;
    height: 40px;
}
.scrollUpBtn {
    position: absolute;
    z-index: 1000;
    left: 90%;
    top: 90%;
}
.scrollUpBtnIcon {
    width: 60px;
    height: 60px;
    position: absolute;
}
.contentWrapperHistoryModalHeader {
    width: 970px;
    display: flex;
    justify-content: flex-end;
}

:global(.ant-table-wrapper) {
    overflow-x: unset;
}
