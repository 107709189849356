.customTabs {
    flex-grow: 1;
}

.cardTitle {
    margin: 0 !important;
}

.modal {
    max-width: 408px !important;
}

.contentHeader {
    height: 3rem;
    width: 100%;
    background-color: white;
}
.contentWrapper {
    width: 100%;
    overflow-y: auto;
}

.pageWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(229, 229, 229);
    justify-content: space-between;
}

.buttonsGroup {
    display: flex;
    flex-direction: row;
    padding-right: 30px;
    align-items: center;
}

.iconBlock {
    display: flex;
    justify-content: center;
}
