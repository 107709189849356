.tabLabel {
}

.tabLabelText {
    position: relative;
}

.tabLabelCount {
    position: absolute;
    color: white;
    font-weight: normal;
    border-radius: 10px;
    line-height: 16px;
    font-size: 12px;
    margin-left: 8px;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
}

.tabLabel.default .tabLabelCount{
    background-color: #1890ff;
}

.tabLabel.danger .tabLabelCount{
    background-color: #ec0909;
}
