.contentWrapper {
  width: 100%;
  overflow-y: auto;
}

.pageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(229, 229, 229);
}