.exportCSVIcon{
  width: 19px;
}
.buttonsGroup{
  padding-right: 25px;
}
.circleIcon {
  z-index: 2;
}
.typesTemplates{
  padding-top: 15px;
}
:global(.modalCSV .ant-modal-content) {
  border-radius: 10px;
  height: 750px;
  overflow: hidden;
  display: flex;
}

// .modal{
//   max-width: 408px!important;
// }

.radioGroup{
  display: flex!important;
  flex-direction: column!important;
}




// .contentHeader {
//   height: 3rem;
//   width: 100%;
//   background-color: white;
// }
// .headerText{
//   padding-left: 15px;
//   font-weight: 400;
// }
// .noTemplate{
//   margin: 1.5rem;
// }
// .contentSwapsUpdateHeader{
//   height: 86px;
//   background-color: white;
//   margin: 1.5rem;
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   justify-content: space-between;
// }
// .formContentHeaderBlockLeft{
//   display: flex;
//   flex-direction: row;
//   justify-content: left;
//   align-items: center;
//   padding-left: 30px;
//   width: 50%;
// }
// .formContentHeaderBlockLeft .headerButton {
//   padding-right: 20px;
//   cursor: pointer;
// }
// .formSwap{
//   padding-right: 20px;
//   width: 242px;
// }
// .formContentHeaderBlockRight{
// display: flex;
// padding-right: 10px;
// }
// .formContentHeaderBlockRight button{
//   margin-right: 20px;
//   width: 80px;
//   height: 32px;
// }

// .inputContainer{
//   display: flex;
//   flex-direction: row!important;
//   justify-content: space-between;
//   flex-wrap: nowrap;
//   align-items: flex-start;
//   width: 100%;
// }

// .types{
//   padding-top: 20px;
// }
// .typesBlock{
//   display: flex;
//   flex-direction: row;
// }


// .logo_image{
//   padding-right: 20px;
//   cursor: pointer;
// }





