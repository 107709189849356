.switchBlock {
    padding-top: 4px;
}
.switchElement {
    display: flex;
    text-align: center;
    padding-left: 4px;
}

.switchLabel {
    line-height: 16px;
    margin-top: -2px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.switch {
    margin-right: 10px !important;
}

.mb10 {
    margin-bottom: 10px;
}