.csvToolTip {
    font-size: 14px;
}

.csvToolTipWindow {
}

.exportIcon {
    padding-top: 0;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}
