.switchBlock {
    padding-top: 4px;
}
.switchElement {
    display: flex;
    text-align: center;
    padding-left: 4px;
}

.switchLabel {
    line-height: 16px;
    margin-top: -2px;
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.switch {
    margin-right: 10px !important;
}

.modifier {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controls {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    gap: 10px;
    align-items: center;
}

.editButton {
    width: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        border-radius: 4px;
        background-color: #d2d2d2;
    }
}

.modifierTags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tagLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    word-wrap: break-word;
    text-wrap: balance;
}

.tag {
    padding: 4px 8px;
    background-color: #c2edff;
    color: #486175;
    display: flex;
    gap: 8px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    line-height: 14px;
}

.tagCross {
    cursor: pointer;
    svg {
        width: 10px;
        line-height: 10px;
        fill: #0072a3;
    }

    &:hover {
        svg {
            opacity: 0.5;
        }
    }
}

.status {
    margin-top: -5px;
    margin-left: -18px;
}

.invalidServers {
    color: #ff4d4f;
    margin-top: -20px;
    margin-bottom: 10px;
}