.typesTemplates {
    padding-top: 15px;
}
:global(.modalCSV .ant-modal-content) {
    border-radius: 10px;
    height: 750px;
    overflow: hidden;
    display: flex;
}

.radioGroup {
    display: flex !important;
    flex-direction: column !important;
}
