.container {
    margin: 0;
    margin-right: 1rem;
    display: flex;
}

.required {
    vertical-align: top;
    color: red;
}
.child{
 display: flex;
 flex-direction: row;
 padding-left: 5px;
 padding-top: 3px;
}
