.switchBlock {
    padding-top: 4px;
}
.switchElement {
    display: flex;
    text-align: center;
    padding-left: 4px;
}

.switchLabel {
    line-height: 16px;
    margin-top: -2px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.switch {
    margin-right: 10px !important;
}

.modifiers {
}

.modifier {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    gap: 10px;
}

.editButton {
    width: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        border-radius: 4px;
        background-color: #d2d2d2;
    }
}

.modifierTags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .tag {
        padding: 4px 8px;
        background-color: #c2edff;
        color: #486175;
        display: flex;
        gap: 8px;
        border-radius: 4px;
        align-items: center;
        justify-content: space-between;
        line-height: 14px;
    }

    .tagCross {
        cursor: pointer;
        svg {
            width: 10px;
            fill: #0072a3;
        }

        &:hover {
            svg {
                fill: #096dd9;
            }
        }
    }
}
