$bgc-error: #f6c0bb;
$bgc-warning: #f8d825;
$bgc-info: #d4e9ff;
$bgc-default: #d3d2d2;

$accent-error: #ec0909;
$accent-warning: #f8d825;
$accent-info: #1890ff;
$accent-default: #939393;

.problemsListContainer {
    position: relative;
    width: 100%;

    .problem {
        background-color: $bgc-default;
        padding: 0 10px;
        margin-bottom: 4px;
        line-height: 18px;

        &.error {
            background-color: $bgc-error;
        }
        &.warning {
            background-color: $bgc-warning;
        }
        &.info {
            background-color: $bgc-info;
        }
    }

    .listContainer {
        width: 100%;
    }

    &.collapsed {
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(black), to(transparent));
        -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
        mask-image: -webkit-gradient(linear, left top, left bottom, from(black), to(transparent));
        mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    .expandButton {
        margin: 0;
        padding: 0;
        line-height: 18px;
        height: 16px;
    }
}

.counters {
    display: flex;
    position: relative;
    gap: 10px;
    margin-top: 4px;
    margin-left: 4px;
    width: auto;

    .count {
        color: white;
        font-weight: normal;
        border-radius: 10px;
        line-height: 13px;
        font-size: 10px;
        margin-left: -6px;
        padding: 0 0px;
        height: 14px;
        min-width: 14px;
        background-color: $accent-default;
        text-align: center;

        &.error {
            background-color: $accent-error;
        }
        &.warning {
            background-color: $accent-warning;
            color: black;
        }
        &.info {
            background-color: $accent-info;
        }
    }
}
