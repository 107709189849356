.pageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(229, 229, 229);
  justify-content: space-between;
}

.contentWrapper {
  width: 100%;
  overflow-y: auto;
}

.buttonsGroup{
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  align-items: center;
}

.iconBlock{
  display: flex;
  justify-content: center;
}

