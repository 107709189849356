@import 'variables';

#root {
    height: 100%;
}
.iconBtn {
    svg {
        stroke: none;
    }
}




