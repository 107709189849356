.container {
  width: 100%;

  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.panelTopFields {
  display: flex;
  flex-direction: column;
  width: 350px;
  min-width: 280px;

  .field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .fieldLabel {
    width: 100%;
    max-width: 120px;
    font-weight: bold;
  }

  .fieldContainer {
    width: 100%;
  }
}

.btnBlock {
  display: flex;
  align-items: end;
}