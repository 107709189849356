
.modalFooterButtons{
    display: flex;
    justify-content: space-between;
}
.modalFooterButton {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; 
}

.btnBlock {
    display: flex;
    grid-gap: 0.5rem;
}

.footerButton {
    height: 32px;
    width: 80px;
}
