.colContentAlign {
    display: flex;

    &_left {
        @extend .colContentAlign;
        justify-content: flex-start;
    }
    &_right {
        @extend .colContentAlign;
        justify-content: flex-end;
    }
    &_center {
        @extend .colContentAlign;
        justify-content: center;
    }
}

.tableHeader {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #304a5c !important;
}

.icon {
    cursor: pointer;
}

.iconRow {
    display: flex;
    justify-content: center;
    align-items: center;

    & > button {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.redIcon {
    color: #ec0909;
}

.blueIcon {
    color: #00a7ee;
}

.precision {
    text-align: right !important;
}

.centered {
    text-align: center !important;
}

.rowFocused {
    background-color: #ebf9ff;
    transition: 100ms;
}

// .rowFocused:hover {
//     background-color: #ebf9ff !important;
// }

.rowFocused:hover > td, .rowFocused > td {
    background-color: inherit !important;
}

.createButton {
    font-weight: bold;
    text-shadow: none;
}

.dragIcon {
    cursor: grab;
    display: flex;
    justify-content: center;
}

// Pagination styling overrideing
:global(.ant-pagination.ant-table-pagination .ant-select-selector .ant-select-selection-item) {
    line-height: 1.4rem;
}
:global(.ant-pagination.ant-table-pagination .ant-pagination-item-link) {
    display: flex;
    justify-content: center;
    align-items: center;
}
