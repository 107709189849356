.importFinaltoButton {
    margin-right: 8px;
    margin-bottom: 4px;
}

.modalTitle {
    display: block;
    overflow: hidden;
    color: #454545;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;

    svg {
        color: #faad14;
        margin-right: 10px;
    }
}
.circleIcon {
    z-index: 2;
}

.serverOptionIcon {
    margin-right: 10px;
    margin-top: -4px;
}

.disabledServer {
    opacity: 0.4;
}

.btnBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.tabs {
    :global(.ant-tabs-tab-disabled .ant-tabs-tab-btn) {
        color: #d9d9d9;
    }
}

.form {
    :global(.ant-btn.ant-btn-primary) {
        width: 120px;
    }
}

.errorsFoundLabel {
    color: #f8acac;
    text-align: right;
    margin-bottom: 10px;
}

:global(.csvErrorsModal .ant-modal-content) {
    width: 500px;
    border-radius: 10px;
}
.csvErrorsModalWrapper {
    max-height: 700px;
    overflow-x: auto;
}

.importCSVBtn img {
    width: 20px;
}

.radioGroup {
    display: flex;
    flex-direction: column;
}

.rowContainerRadio {
    display: flex !important;
    flex-direction: row;
}

.radioGroupLabel {
    text-wrap: nowrap;
}

.modal {
    min-width: 550px;

    @media screen and (max-width: 900px) {
        min-width: unset;
    }
}