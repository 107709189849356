.loader {
    position: absolute!important;
    top: 50%!important;
    left: 50%!important;
    z-index: 2!important;
}
.nonAuth{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
}
.nonAuthError{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    color: red;
}