.formSwitch {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 15px;
}

.server {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .switchBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-left: 4px;

        :global(span.ant-typography) {
            line-height: 16px;
            width: 100%;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
    }

    .switch {
        margin-right: 10px !important;
    }
}

.serversContainer {
    .serverCollapse {
        margin: -24px;
    }
}
