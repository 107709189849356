.circleIcon {
    z-index: 2;
}

.modalImport :global(.ant-modal-content) {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
}

.errorContainer {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 40px;
}

.errorRecord {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #efefef;
}
