.addButton {
    cursor: pointer;
    display: flex;
    padding: 4px;
    border-radius: 4px;
    color: #247fea;
    font-size: 12px;
    margin-top: 8px;

    img {
        width: 16px;
        margin-right: 8px;
    }
    &:hover {
        background-color: #efefef;
    }
}

.containerIsDragging {
}

.modifierComponent {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    :global(.ant-collapse-header) {
        padding: 0 !important;
    }
    :global(.ant-collapse),
    :global(.ant-collapse-extra) {
        width: 100%;
    }
    .collapsePanel {
    }

    .selectModifierType {
        display: flex;
        width: 100%;
        padding-right: 6px;
        margin-left: -10px;

        .orderId {
            width: 20px;
            color: #75889e;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .dragIcon {
        margin-top: 6px;
    }

    :global(.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
        cursor: pointer;
        right: -4px;

        svg {
            width: 14px;
            fill: #75889e;
        }
    }

    :global(.ant-divider) {
        margin: 20px 0 0 0;
    }
    :global(.ant-collapse-content) {
        margin-left: 0;
        width: calc(100% + 30px);
    }
}

.deleteIcon {
    width: 14px;
    cursor: pointer;
    margin-left: 4px;

    &:hover {
        opacity: 0.8;
    }
}