#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.sider {
    height: calc(100vh - 96px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
}

.sider::-webkit-scrollbar {
    display: none;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.siteLayoutContent {
    background: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: calc(100vh - 4em);
}

.rightSection {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    line-height: 16px;

    &_welcome {
        margin: 0 1.5rem 0 0;
        font-weight: 700;
    }

    &_login {
        font-weight: bold;
    }
}

.leftSection {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    line-height: 16px;
    padding-left: 50px;

    &_server {
        margin: 0 1.5rem 0 0;
        font-weight: 700;
    }

    &_serverName {
        font-weight: bold;
    }
}

.logoutBlock {
    display: flex;
    align-items: center;
    width: 35%;
    flex-direction: row;
    cursor: pointer;

    &_text {
        margin: 0 0 0 0.6rem;
        color: #c2edff;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    &_icon {
        color: #c2edff;
    }
}

.header {
    background-color: #14213d !important;
    height: 48px !important;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    margin: 0.5rem 0;
    // &_image {
    //     width: 38px;
    // }
    &_titleBlock {
        display: flex;
        flex-direction: column;
    }
    &_title {
        margin: 0;
        // font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        font-family: 'Montserrat';
        font-weight: 600;
    }

    &_subtitle {
        @extend .logo_title;
        font-weight: 300;
        font-size: 12px;

        line-height: 16px;
    }
}

.layoutContent {
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headerContent {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.menuIcon {
    margin-right: 0.5rem;
    width: 1rem !important;
}

.outerMenu {
    height: 100%;
    border-right: 0;
    color: #486175;
    font-weight: bold;
}

.menuItem {
    color: #486175;
    font-weight: normal;
}

.menuItem.ant-menu-item-selected {
    color: #00a7ee;
}

.ant-menu-item-selected a {
    color: #00a7ee;
}

.ant-menu-submenu-selected {
    color: #1fbcff;
}

.footerBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.versionText {
    margin: 0;
    font-family: roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.versionBlock {
    opacity: 0;
    animation: show 0.2s 0.1s forwards;
    text-align: left;
    padding-right: 16px;
}

.auth {
    &_versionBlock {
        position: absolute;
        bottom: 16px;
        left: 16px;
    }

    &_versionText {
        @extend .versionText;
    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

.logoutBlock_icon {
    padding: 5px;
}

@media screen and (max-width: 992px) {
    :global(.ant-layout-header) {
        padding: 0 8px !important;
    }
    .leftSection {
        padding-left: 8px;
        &_server {
            font-size: 12px;
            line-height: 12px;
            margin-right: 4px;
        }
        &_serverName {
            font-weight: normal;
        }

        :global(.ant-btn) {
            padding: 4px 8px;
        }
    }
    .rightSection {
        font-size: 12px;

        &_welcome {
            margin-right: 4px;
        }
    }
    .logoutBlock {
        width: auto;
    }
}
