.rightPanel {
    overflow-y: hidden;
    min-width: 382px;
    width: 382px;
    min-height: 100%;
    max-height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;
    &__title {
        margin: 11px 22px;
    }
    &__header {
        z-index: 2;
        position: absolute;
        top: 5px;
        right: 0;
        display: flex;
        justify-content: flex-end;
        &_required {
            vertical-align: top;
            color: red;
            margin-left: 4px;
        }
        &_icon {
            color: #00a7ee;
        }
        &_wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
    }
    &__scrollable{
        height: calc(100% - 145px);
        overflow-y: auto;
    }
    &__noFooter {
        height: calc(100% - 45px);
    }
    &__resetBtn {
        margin-right: 8px;
    }
    &__footer {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 20px 24px;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        width: 100%;
    &__btnGroup{
        display: flex;
        justify-content: space-between;
        justify-content: flex-end;
        width: 100%;
        & .btn_custom {
            margin-right: 10px;
        }
    }
    }
    & :global(.ant-collapse-item.ant-collapse-item-active) {
        background-color: rgb(240, 243, 250);
    }
    & :global(.collapse-header) {
        font-weight: bold;
        margin: unset;
    }
    & :global(#symbols-group-tag-selector .ant-select-selector) {
        height: auto;
    }
    & :global(.ant-collapse-item .ant-collapse-header) {
        align-items: unset !important;
    }
    & :global(.ant-collapse-item .ant-collapse-header div) {
        display: flex;
        align-items: center;
        min-height: 100%;
    }
}

.readOnly {
    &__item {
        & :global(.ant-collapse-item.ant-collapse-item-active) {
            background-color: white;
            margin: 0 22px;
        }

        & :global(.ant-collapse-item) {
            margin: 0 22px;
            box-shadow: 0px 1px 4px rgba(30, 50, 92, 0.1);
            border-radius: 4px;
        }
    }
}

.spinner {
    position: absolute!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
}

.collapsePanel{
    padding-left: 8px;
    padding-right: 8px;
}

.card,
:global(.ant-card).card {
    background-color: #F0F3FA;
}

.circleIcon {
    z-index: 2;
}

.spinnerBackdrop {
    z-index: 999999;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.644);
}

.infoCard {
    margin: 24px;
}

:global(.ant-card-head-title){
    white-space: unset;
}

@media screen and (max-width: 1024px) {
    .menu_open {
        min-width: 100%;
    }
}

@media screen and (max-width: 988px) {
    .menu_collapsed {
        min-width: 100%;
    }
}
