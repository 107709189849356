.serverTypes{
  width: 100%;
  display: flex;
  justify-content: space-evenly!important;
}


.typesTemplates{
  padding-top: 15px;
}

.radioGroup {
  display: flex !important;
  flex-direction: column !important;
}