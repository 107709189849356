@import '@styles/variables';

.contentHeader {
    height: 3rem;
    width: 100%;
    background-color: $white;
}

.contentWrapper {
    width: 100%;
    overflow-y: auto;
}

.pageWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(229, 229, 229);
    justify-content: space-between;
}
.formSwitch {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 15px;
}
.formSwitchBlock {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    width: 100%;
    padding-bottom: 20px;
}
.switchBlock {
    padding-top: 20px;
}
.switchElement {
    display: flex;
    text-align: center;
    padding-left: 4px;

    :global(span.ant-typography) {
        line-height: 16px;
        margin-top: -4px;
    }
}
.switch {
    margin-right: 10px !important;
}
.headerButtonBack {
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerText {
    color: $textMenuColor;
    font-size: 28px;
    font-weight: bold;
    padding-left: 10px;
}
.buttonsGroup {
    display: flex;
    gap: 15px;
    height: 100%;
    align-items: end;
    margin-left: 20px;
    margin-right: 20px;
}

.headerButton {
    padding-right: 15px;
    cursor: pointer;
}
.maskInput {
    width: 90% !important;
    overflow: visible !important;
}
:global(.expandedTableValidation .ant-table-thead) {
    display: none;
}
:global(.expandedTableValidationSymbols .ant-table-thead) {
    display: contents;
}

:global(.validationTableNoChanges.ant-table-row) {
    background-color: #fcd17b;
    pointer-events: none;
}

:global(.validationTableNotExpandable.ant-table-row) {
    pointer-events: none;
}
:global(.validationTableNoChanges:hover td) {
    background: #fcd17b !important ;
    pointer-events: none;
}

:global(.validationTable .ant-table-thead .ant-table-cell) {
    background-color: #d3dce7 !important;
}

:global(.validationTable.ant-table-row) {
    background-color: #f4f8fc !important;
}

:global(.validationTable.ant-table-row .ant-table-cell) {
    cursor: pointer;
}

:global(.editedRowTable.ant-table-row) {
    background-color: #ebf9ff !important;
}

:global(.validationTableServerError:hover td) {
    background: #f1958e !important ;
    pointer-events: none;
}
:global(.validationTableServerError.ant-table-row) {
    background-color: #f1958e;
    pointer-events: none;
}

:global(.validationModal .ant-modal-content) {
    border-radius: 10px;
    height: 550px;
    overflow: hidden;
    display: flex;
}

:global(.historyModal .ant-modal-content) {
    border-radius: 10px;
    height: 670px;
    overflow: hidden;
    display: flex;
    // align-items: end;
    flex-direction: column;
}

:global(.changedRow.ant-table-row) {
    background-color: red !important;
}
.validationPageWrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-content: space-between;
}

.contentWrapperHistoryModalHeader {
    width: 970px;
    display: flex;
    justify-content: flex-end;
}

.headerBlock {
    align-items: flex-start;
    height: 20%;
}
.tableBlock {
    height: 270px;
}
.collapseIcon {
    width: 10px;
    height: 10px;
}

.closeBtn {
    margin-top: 20px;
    width: 100%;
}

.serversSpin {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $mainTextColor;
}

.validationBtnGroup {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding-top: 20px;
}
.historyBtnClose {
    width: 1000px;
}
.historyBtnClose button {
    width: 100%;
}

.validationBtnClose {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 10%;
}
.errorIcon {
    display: flex;
    justify-content: center;
}

.errorCounterMT {
    width: 66%;
    text-align: right;
}

.errorCounterFIX {
    width: 76%;
    text-align: right;
}
.rcTooltipCsv {
    display: block;
}
.srcTooltipHidden {
    display: none;
}

.csvToolTip {
    font-size: 14px;
}
.containerModal {
    position: relative;
}
.scrollUpBtn {
    position: absolute;
    z-index: 1000;
    left: 90%;
    top: 90%;
}
.scrollUpBtnIcon {
    width: 60px;
    height: 60px;
    position: absolute;
}
.headerBlocks {
    // background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}
.headerContainerLeft {
    display: flex;
    flex-direction: column;
}

.headerContainerLogo {
    width: 40px;
    height: 40px;
}

.deleteTemplateBtn {
    margin: 0;
}

.buttonsGroupTableDetails {
    flex-wrap: wrap;

    .exportCSVIcon {
        margin-bottom: 4px;
        width: 24px;
    }

    .importCSVBtnWrapper {
        margin-bottom: 4px;
        cursor: pointer;
        img {
            width: 24px;
        }
    }
}
