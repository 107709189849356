.contentWrapper {
  width: 100%;
  overflow-y: auto;
}

.pageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(229, 229, 229);
}

.settingsFormWrapper{
  display: flex;
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 1.5rem;
}
.settingsFormContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}
.settingsFormInput{
  width: 200px;
}
.settingsFormFooter{
  padding-top: 25px;
}
.settingsFormContainerLabel{
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.settingsFormContainerForm{
  width: 200px;
}
